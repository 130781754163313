// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAction } from '@reduxjs/toolkit';

export const SetDensityAction = createAction<string>('bundle/SetDensityAction');

export const SetDeliverablesAction = createAction<IBundle>('bundle/SetDeliverablesAction');

export const SelectedAdditionalDeliverablesAndLayersAction = createAction<IDeliverable>('bundle/SelectedAdditionalDeliverablesAndLayersAction');

export const SetDeliveryDateAction = createAction<string>('bundle/SetDeliveryDateAction');

export const SetDeliveryDaysAction = createAction<number>('bundle/SetDeliveryDaysAction');

export const SetTopoMajrAction = createAction<number>('bundle/SetTopoMajrAction');

export const SetTopoMinrAction = createAction<number>('bundle/SetTopoMinrAction');

export const GetBundlesStartAction = createAction('bundle/GetBundlesStartAction');
export const GetBundlesSuccessAction = createAction<IBundleResponse>('bundle/GetBundlesSuccessAction');
export const GetBundlesFailureAction = createAction('bundle/GetBundlesFailureAction');

export const ResetBundleStateAction = createAction('bundle/ResetBundleStateAction');
export const SetNewCustomBundleAction = createAction<IBundle>('bundle/SetNewCustomBundleAction');
